<template>
  <v-container>
    <v-data-table :headers="headers" :items="allocations" item-key="name" class="elevation-1">
      <!--AÇÕES-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex flex-no-wrap">
          <v-icon v-if="item != allocations[allocations.length - 1] && item.allocated" size="x-large" color="red"
            @click="exoneraAllocation(item)" title="Exonerar">
            mdi-alert-octagon-outline
          </v-icon>
          <!--ICONE 3 PONTOS QUE ABRE OPÇÃO DE EXCLUIR E EDITAR-->
          <v-menu bottom left v-if="item !== allocations[allocations.length - 1] && !item.is_editing">
            <template v-slot:activator="{ on }">
              <v-icon size="x-large" color="black" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <!--LISTA DOS ITENS DE 3 PONTOS-->
            <v-list>
              <!--OPÇÃO DE EDITAR-->
              <v-list-item v-if="!item.allocated" @click="editAllocation(item)">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <!--OPÇÃO DE EXCLUIR-->
              <v-list-item @click="deleteAllocation(item)">
                <v-list-item-title>Deletar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--ICONE DE ADICIONAR LOTAÇÃO-->
          <v-icon class="ml-2" v-if="item === allocations[allocations.length - 1]" size="x-large" color="green"
            @click="postAllocation(item)">
            mdi-plus-circle
          </v-icon>

          <!--ÍCONES PARA CARGO EM EDIÇÃO-->
          <v-icon v-if="item.is_editing" size="x-large" color="red" @click="cancelAllocationEdit(item)" title="Cancelar">
                  mdi-cancel
          </v-icon>
          <v-icon v-if="item.is_editing" class="pl-1" size="x-large" color="green" @click="updateAllocation(item)" title="Confirmar">
                  mdi-check-circle
          </v-icon>
        </div>
      </template>
      <!--Setor-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.department="{ item }">
        <v-select :items="sectors" v-if="item === allocations[allocations.length - 1]" v-model="item.department" label="Setor*" item-text="name" item-value="ID"
          :rules="[rules.required]">
        </v-select>
        <template v-else>
          <span :style="{ color: !item.allocated && !item.is_editing ? 'gray' : 'black' }">
            {{ item.department }}
          </span>
        </template>
      </template>

      <!--SIGLA-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.acronym="{ item }">
        <span v-if="item === allocations[allocations.length - 1]">
          {{ sectors.find(sector => sector.ID === item.department)?.acronym }}
        </span>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ item.sectors.acronym }}
          </span>
        </template>
      </template>

      <!--RAMAL-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.extension="{ item }">
        <span  v-if="item === allocations[allocations.length - 1]">
          {{ sectors.find(sector => sector.ID === item.department)?.extension }}
        </span>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ item.sectors.extension }}
          </span>
        </template>
      </template>

      <!--Entrada no setor-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.entryDate="{ item }">
        <v-text-field v-if="item === allocations[allocations.length - 1]" v-model="item.entryDate"
          label="Entrada" type="date" :rules="[rules.required]"></v-text-field>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ formatDate(item.entryDate) }}
          </span>
        </template>
      </template>

      <!--Saída do setor-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.exitDate="{ item }">
        <v-text-field v-if="item === allocations[allocations.length - 1] || item.is_editing" v-model="item.exitDate"
          label="Saída" type="date" :rules="[rules.required]"></v-text-field>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ formatDate(item.exitDate) }}
          </span>
        </template>
      </template>

      <!--Chefia-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.isLeadership="{ item }">
        <v-checkbox v-if="item === allocations[allocations.length - 1]" v-model="item.isLeadership">
        </v-checkbox>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ item.isLeadership ? 'Sim' : 'Não' }}
          </span>
        </template>
      </template>

      <!--Cidade de lotação-->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.workCity="{ item }">
        <span v-if="item === allocations[allocations.length - 1]">
          {{ sectors.find(sector => sector.ID === item.department)?.workCity }}
        </span>
        <template v-else>
          <span :style="{ color: !item.allocated && item.is_editing === false ? 'gray' : 'black' }">
            {{ item.sectors.workCity }}
          </span>
        </template>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'

export default {
  data() {
    return {
      itemToModify: null,
      headers: [
        { text: 'SETOR', value: 'department', width: '150px', sortable: false, align: 'center' },
        { text: 'SIGLA', value: 'acronym', sortable: false },        
        { text: 'RAMAL', value: 'extension', sortable: false },
        { text: 'ENTRADA NO SETOR', value: 'entryDate', sortable: false },
        { text: 'SAÍDA DO SETOR ', value: 'exitDate', sortable: false },
        { text: 'CHEFIA', value: 'isLeadership', sortable: false },
        { text: 'CIDADE DE LOTAÇÃO', value: 'workCity', sortable: false },
        { text: 'AÇÕES', value: 'actions', sortable: false, align: 'center', width: '100px' }
      ],
      sectors: [],
      allocations: [
        {
          department: '',
          entryDate: '',
          exitDate: '',
          isLeadership: false,
          workCity: '',
          allocated: true,
          is_editing: false
        }
      ],
      rules: {
        required: value => !!value || 'Campo obrigatório.'
      }
    }
  },
  props:{
    person: Object
  },
  watch:{
    person: {
      handler() {
        if(this.person.ID){
          this.fetchAllocationData();
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    cancelAllocationEdit(item) {
      if(confirm('Tem certeza que deseja cancelar a edição?')) {
        item.is_editing = false;
        this.fetchAllocationData();
        }
    },
    //Usado para formatar a data para o formato yyyy-mm-dd na função de edição
    formatDateToYYYYMMDD(value) {
          if (!value) return '';
          var date = new Date(value);
          var userTimezoneOffset = date.getTimezoneOffset() * 60000;
          date = new Date(date.getTime() + userTimezoneOffset);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${year}-${month}-${day}`;
    },
    editAllocation(item) {
      this.itemToModify = item;
      item.is_editing = true;

      // Converte a data para o formato yyyy-mm-dd
      item.exitDate = this.formatDateToYYYYMMDD(item.exitDate);
      item.entryDate = this.formatDateToYYYYMMDD(item.entryDate);
    },
    // Tira o formato ISO da data para exibir pro usuário (##/##/####)
    formatDate(value) {
      if (!value) return ''
      var date = new Date(value)
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
      
    },
    fetchSectors(){
      axios.get(`${urlbackend}/rh/sector/`)
      .then(response => {
        this.sectors = response.data;
        //console.log(this.sectors);
      })
      .catch(error => {
        console.error('Erro ao buscar setores:', error);
      });
    },
    createEmptyCareer() {
      return {
        department: '',
        entryDate: '',
        exitDate: '',
        isLeadership: false,
        workCity: '',
        allocated: true
      };
    },
    async postAllocation(item) {
      const requiredFields = ['entryDate', 'department'];
      // Verifica se todos os campos obrigatórios estão preenchidos
      if (requiredFields.some(field => !item[field])) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }

      if (!confirm('Tem certeza que deseja adicionar este cargo?')) {
        return;
      }

      const payload = {
        entryDate: item.entryDate ? new Date(item.entryDate) : null,
        sector: item.department,
        person: this.person.ID,
        isLeadership: item.isLeadership || false,
        allocated: item.exitDate ? false : true,
        ...(item.exitDate && { exitDate: new Date(item.exitDate) }) // Adiciona exitDate condicionalmente
      };

      try {
        const urlPost = `${urlbackend}/rh/allocation/`;
        await axios.post(urlPost, payload);
        // Atualiza a tabela com os novos dados do backend
        await this.fetchAllocationData();

        // Emite evento para atualizar a alocação na tela de pessoa
        this.$emit('update-allocation');
      } catch (error) {
        console.error('Erro ao adicionar cargo:', error);
        alert('Erro ao adicionar cargo.');
      }
    },
    async exoneraAllocation(item) {
      if (!confirm('Tem certeza que deseja exonerar este cargo?')) {
        return;
      }

      try {
        const urlPut = `${urlbackend}/rh/allocation/${item.ID}`;
        await axios.put(urlPut, { allocated: false, exitDate: new Date() });
        alert('Lotação exonerado com sucesso!');

        // Atualiza a tabela com os novos dados do backend
        await this.fetchAllocationData();

        // Emite evento para atualizar a alocação na tela de pessoa
        this.$emit('update-allocation');

      } catch (error) {
        console.error('Erro ao exonerar cargo:', error);
        alert('Erro ao exonerar cargo.');
      }
    },
    async updateAllocation(item) {
      const requiredFields = ['exitDate'];
      // Verifica se todos os campos obrigatórios estão preenchidos
      if (requiredFields.some(field => !item[field])) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }

      if (!confirm('Tem certeza que deseja atualizar este cargo?')) {
        return;
      }

      try {
        const urlPut = `${urlbackend}/rh/allocation/${item.ID}`;

        await axios.put(urlPut, { exitDate: item.exitDate ? new Date(item.exitDate) : null });

        // Atualiza a tabela com os novos dados do backend
        await this.fetchAllocationData();

        // Emite evento para atualizar a alocação na tela de pessoa
        this.$emit('update-allocation');
      } catch (error) {
        console.error('Erro ao atualizar cargo:', error);
        alert('Erro ao atualizar cargo.');
      }
    },
    async deleteAllocation(item) {
      if (!confirm('Tem certeza que deseja excluir este cargo?')) {
        return;
      }

      try {
        const urlDelete = `${urlbackend}/rh/allocation/${item.ID}`;
        await axios.delete(urlDelete, { allocated: false });
        alert('Lotação excluída com sucesso!');

        // Atualiza a tabela com os novos dados do backend
        await this.fetchAllocationData();

        // Emite evento para atualizar a alocação na tela de pessoa
        this.$emit('update-allocation');
      } catch (error) {
        console.error('Erro ao excluir cargo:', error);
        alert('Erro ao excluir cargo.');
      }
    },
    async fetchAllocationData() {
      try {
        const urlGet = this.person?.ID ? `${urlbackend}/rh/allocation/person/${this.person.ID}` : `${urlbackend}/rh/allocation/`;
        const response = await axios.get(urlGet);
        this.allocations = response.data.map(item => ({
          department: item.sectors?.name || '',
          sectorID: item.sectors?.ID, // 🔹 Mantém ID do setor separado
          ...item, // 🔹 Mantém ID correto da alocação
          is_editing: false
        }));

        // Adiciona um item vazio para adicionar novos cargos
        this.allocations.push(this.createEmptyCareer());
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    },
  },
  mounted() {
    this.fetchAllocationData();
    this.fetchSectors();
  }
}
</script>